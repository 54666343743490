import { ButtonIcon, DetailPageDownloadSvg } from '@canalplus/dive';
import { customProtocolCheck } from '@canalplus/mycanal-commons';
import { ModalV2, ModalV2Size, ModalV2Type } from '@canalplus/mycanal-sharedcomponent';
import { SecondaryActionState } from '@dce-front/hodor-types/modules/action_layout/definitions';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Badge from '../../../../../../components/Badge/Badge';
import { DownloadStateId } from '../../../../../../constants/DownloadToGo';
import { getPublicConfig } from '../../../../../../helpers/config/config-helper';
import { useAppDispatch } from '../../../../../../helpers/hooks/useAppDispatch';
import I18n from '../../../../../../lang';
import { renderSourceSelector } from '../../../../../../selectors/application/application-selectors';
import { openDownloadToGo } from '../../../../../../store/reducers/actions/DownloadToGo-actions';
import { useDetailContext } from '../../../../../../templates/DetailV5/data/provider';
import stylesSecondaryActions from '../SecondaryActions.css';
import styles from './D2G.css';
import D2GUnavailableModal from './D2GUnavailableModal/D2GUnavailableModal';

export type RenderD2GProps = {
  isTvDevice: boolean;
  contentID: string;
  showLabel?: boolean;
  statesLabel?: SecondaryActionState[];
};

export const TIMEOUT_PROTOCOL_DETECT = 4000;

function RenderD2G({ isTvDevice, contentID, statesLabel, showLabel = true }: RenderD2GProps): JSX.Element {
  const [isModalOpen, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();

  const config = getPublicConfig();
  const { t } = I18n.useTranslation();

  const isClient = useSelector(renderSourceSelector) === 'client';

  const pathContent = useDetailContext()?.currentPage?.path;
  const protocolHandlerUrl = config.DOWNLOADTOGO.CUSTOM_PROTOCOL_URL.replace(
    '{pathContent}',
    pathContent || ''
  ).replace('{contentId}', contentID);

  // @TODO Manage label from state of download. Need to copy logic OnePlayer_D2G from detail v4 (apps/mycanal/src/components/ActionBar/ActionBar.tsx)
  // Before this change, use always the label NotDownloaded
  const stateLabel = statesLabel?.find((elem) => elem.id === DownloadStateId.NotDownloaded);
  const downloadLabel = stateLabel?.label || t('DownloadToGo.downloads');

  const triggerD2G = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isClient) {
      if (event) {
        event.stopPropagation();
      }
      dispatch(openDownloadToGo());

      customProtocolCheck(
        protocolHandlerUrl,
        // Failed callback
        () => {
          setOpenModal(true);
        },
        // Success callback
        () => {
          setOpenModal(false);
        },
        TIMEOUT_PROTOCOL_DETECT,
        () => {
          setOpenModal(true);
        }
      );
    }
  };

  return (
    <>
      {isTvDevice ? (
        <button
          className={styles.downloadBtn}
          onClick={triggerD2G}
          tabIndex={0}
          data-tool-id="click-download"
          type="button"
          aria-label={downloadLabel}
          id="downloadBtnId"
        >
          <Badge
            theme="button"
            customClassBadgeV5={stylesSecondaryActions.secondaryActions__D2GBtn}
            hasHoverStyle={false}
          >
            <DetailPageDownloadSvg title={downloadLabel} />
            {showLabel && <span className={styles.downloadBtn__text}>{downloadLabel}</span>}
          </Badge>
        </button>
      ) : (
        <ButtonIcon
          id="downloadBtnId"
          aria-label={downloadLabel}
          icon={<DetailPageDownloadSvg title={downloadLabel} />}
          onClick={triggerD2G}
          {...(showLabel && { label: downloadLabel })}
        />
      )}
      {isModalOpen && (
        <ModalV2
          title={t('DownloadToGoMacOsModal.title')}
          size={ModalV2Size.D2G}
          type={ModalV2Type.D2G}
          onClose={() => setOpenModal(false)}
          portalId={contentID}
          idFrom={document.getElementById('downloadBtnId')}
          closeAriaLabel={t('DownloadToGoMacOsModal.closeBtn')}
        >
          <D2GUnavailableModal />
        </ModalV2>
      )}
    </>
  );
}

export default RenderD2G;
